import { createTheme } from '@mui/material'

export const themeColors = {
  blue10: '#0E2262',
  blue40: '#2D42B6',
  blue60: '#6A88E9',
  blue80: '#C6D0EE',
  blue90: '#ECEFF9',
  teal90: '#00728E',
  teal40: '#00A0C7',
  teal50: '#5ECAE4',
  teal60: '#B2E4F0',
  teal80: '#E5F9FE',
  red93: '#BF210E',
  red92: '#ED4B37',
  red94: '#F57D7E',
  red95: '#FFDEDA',
  gold90: '#C9A749',
  gold50: '#D7BD73',
  gold60: '#E8D59E',
  gold80: '#F2E7C6',
  grayblack: '#000000',
  gray10: '#191919',
  gray20: '#434343',
  gray30: '#F5F5F5',
  gray40: '#646464',
  gray50: '#AAAAAA',
  gray60: '#969696',
  gray70: '#C8C8C8',
  gray80: '#F9F7F5',
  gray90: '#E1E1E1',
  gray99: '#F7F7F7',
  orange: '#EDAF37',
  gray: '#3F3F3F',
  grayWhite: '#F4F4F4',
  red: '#BF210E',
  darkRed: '#A81A09',
  blue: '#2D42B6',
  darkBlue: '#112698',
  green: '#29B974',
  yellow: '#EDAF37',
  white: '#FFFFFF',
  lightPurple: '#EDF1FC',
  darkPurple: '#7705B5',
  gold: '#FFD700',
  Tiffany: '#0ABAB5',
}

const textFieldBaseStyles = {
  background: themeColors.white,
  width: '100%',
  marginLeft: 0,
  height: '36px',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '5px',
  border: `none`,
  '&.Mui-disabled input, textarea, options': {
    background: themeColors.gray30,
    color: themeColors.gray60,
  },

  option: {
    width: '100%',
    margin: 'auto',
    fontWeight: 500,
    fontSize: '14px',
    borderRadius: '5px',
    background: 'none',
    color: themeColors.gray20,
  },

  textarea: {
    width: '100%',
    margin: 'auto',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    borderRadius: '5px',
    background: 'none',
    color: themeColors.gray20,
    minHeight: '150px',
  },

  input: {
    width: '100%',
    margin: 'auto',
    fontWeight: 500,
    fontSize: '14px',
    borderRadius: '5px',
    background: 'none',
    color: themeColors.gray20,
  },
}

export const theme = createTheme({
  components: {
    MuiInput: {
      variants: [
        {
          props: { type: 'text' },
          style: {
            backgroundColor: themeColors.gray80,
            borderRadius: '5px',
            minHeight: '40px',
            padding: '10px 13px',
            width: '240px',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            lineHeight: '14px',
            verticalAlign: 'middle',
          },
        },
        {
          props: { type: 'password' },
          style: {
            backgroundColor: themeColors.gray80,
            borderRadius: '5px',
            minHeight: '40px',
            padding: '10px 13px',
            width: '240px',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            lineHeight: '14px',
            verticalAlign: 'middle',
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: `1px solid ${themeColors.blue40}`,
          height: '24px',
          backgroundColor: themeColors.gray99,
          borderRadius: '5px',
        },
        label: {
          padding: '12px',
          fontWeight: 500,
          fontSize: '14px',
          color: themeColors.gray20,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: textFieldBaseStyles,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ...textFieldBaseStyles,
        },
        option: {
          fontSize: '14px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: themeColors.blue40,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
          height: '30px',
          borderRadius: '7px',
          fontFamily: 'Lato',
          fontWeight: 'bold',
          fontSize: '12px',
          lineHeight: '20px',
          align: 'center',
          padding: '3px 15px',
          verticalAlign: 'center',
          boxSizing: 'border-box',
          minWidth: '100px',
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            boxSizing: 'border-box',
            background: themeColors.white,
            outline: `2px solid ${themeColors.blue}`,
            outlineOffset: `-2px`,
            color: themeColors.blue,

            '&:hover': {
              background: themeColors.grayWhite,
              outline: `2px solid ${themeColors.darkBlue}`,
              outlineOffset: '-2px',
              color: themeColors.darkBlue,
              fontWeight: 700,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            boxSizing: 'border-box',
            background: themeColors.white,
            outline: `2px solid ${themeColors.red}`,
            outlineOffset: '-2px',
            color: themeColors.red,

            '&:hover': {
              background: themeColors.grayWhite,
              outline: `2px solid ${themeColors.darkRed}`,
              outlineOffset: '-2px',
              color: themeColors.darkRed,
              fontWeight: 700,
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            background: themeColors.blue,
            border: `2px solid ${themeColors.blue}`,
            color: themeColors.white,

            '&:hover': {
              border: `2px solid ${themeColors.darkBlue}`,
              background: themeColors.darkBlue,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            background: themeColors.red,
            border: `2px solid ${themeColors.red}`,
            color: themeColors.white,

            '&:hover': {
              border: `2px solid ${themeColors.darkRed}`,
              background: themeColors.darkRed,
            },
          },
        },
      ],
    },
  },
  spacing: 10,
  palette: {
    primary: {
      main: themeColors.grayblack,
      dark: themeColors.gray20,
      light: themeColors.gray80,
      contrastText: themeColors.gray90,
    },
    secondary: {
      main: themeColors.gray90,
      dark: themeColors.gray20,
      contrastText: themeColors.gray20,
    },
    error: {
      light: themeColors.red94,
      main: themeColors.red93,
      contrastText: themeColors.gray90,
    },
    warning: {
      main: themeColors.gold50,
    },
    success: {
      light: themeColors.teal60,
      main: themeColors.teal90,
    },
    info: {
      main: themeColors.blue,
    },
    background: {
      default: themeColors.teal90,
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Lato',
    },
    button: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '16px',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '30px',
      padding: '10px',
      paddingLeft: '40px',
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '24px',
      padding: '10px',
    },
    h5: {
      fontWeight: 'bolder',
      fontSize: '17px',
      padding: '10px',
    },
    h6: {
      fontWeight: 'bold',
      fontSize: '16px',
      color: themeColors.grayblack,
      padding: '10px',
    },
    caption: {
      fontSize: '13px',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '18px',
    },
    body2: {
      fontWeight: 'bold',
      fontSize: '14px',
    },
    subtitle1: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bolder',
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '2px',
      color: themeColors.grayblack,
    },
    subtitle2: {
      fontSize: '16px',
    },
    fontFamily: [
      'Lato',
      '-apple-system',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
})
